$primary:                           #00A49E;
$primary-light:                     lighten($primary, 5%);
$primary-dark:                      #037A71;
$primary-opaque-light-1:            rgba($primary, 0.1);
$primary-opaque-light-2:            rgba($primary, 0.2);
$primary-opaque-dark:               rgba($primary, 0.5);
$primary-text-highlight:            rgba($primary, 0.8);

$enterprise-primary:                #2C96DD;
$enterprise-primary-light:          lighten($enterprise-primary, 5%);
$enterprise-primary-dark:           #2B90BE;
$enterprise-primary-opaque-light-1: rgba($enterprise-primary, 0.1);
$enterprise-primary-opaque-light-2: rgba($enterprise-primary, 0.2);
$enterprise-primary-opaque-dark:    rgba($enterprise-primary, 0.5);
$enterprise-primary-text-highlight: rgba($enterprise-primary, 0.8);

$rebrand:                           #3589CD;
$rebrand-light:                     lighten($rebrand, 5%);
$rebrand-dark:                      darken($rebrand, 10%);
$rebrand-opaque-light-1:            rgba($rebrand, 0.1);
$rebrand-opaque-light-2:            rgba($rebrand, 0.2);
$rebrand-opaque-dark:               rgba($rebrand, 0.5);
$rebrand-text-highlight:            rgba($rebrand, 0.8);

$white:                             #FFFFFF;
$ghost-white:                       #F5F5F5;
$grey-f:                            #FAFAFA;
$grey-e:                            #E8E8E8;
$grey-d:                            #D9D9D9;
$grey-c:                            #CCCCCC;
$grey-b:                            #BCBCBC;
$grey-9:                            #999999;
$grey-8:                            #808080;
$grey-7:                            #7D7D7D;
$grey-6:                            #6B6B6B;
$grey-5:                            #575757;
$grey-4:                            #454545;
$grey-3:                            #333333;
$black:                             #000000;

$emphasis-green:                    #76BA43;
$emphasis-yellow:                   #FBB01F;
$emphasis-red:                      #F01404;
$emphasis-purple:                   #794C9F;
$emphasis-grey:                     $grey-5;

$job-state-scheduled:               #4A80E4;
$job-state-active:                  #76BA43;
$job-state-paused:                  #FBB01F;
$job-state-declined:                #F01404;
$job-state-in-transit:              #794C9F;
$job-state-completed:               #575757;
$job-state-no-show:                 #999999;

$scheduler-active-blue:             #4A81E5;
$scheduler-active-blue-dark:        #3A6ABF;
$scheduler-active-blue-light:       #5FA6ED;

$danger:                            $emphasis-red;
$danger-light:                      lighten($emphasis-red, 20%);
$danger-dark:                       darken($emphasis-red, 5%);
$danger-opaque:                     rgba($danger, 0.2);

$selected-yellow:                   #FFFDF6;

$text-header:                       rgba(0, 0, 0, 0.85);
$text-body:                         rgba(0, 0, 0, 0.65);
$text-subtitle:                     rgba(0, 0, 0, 0.45);
$text-disabled:                     rgba(0, 0, 0, 0.25);
$text-group-header:                 rgba(0, 0, 0, 0.75);

$scroll-container-box-shadow:       rgba(0, 0, 0, 0.15);
$scroll-thumb:                      #7E7E7E;
$scroll-track:                      $ghost-white;

$editable-cell-hover:               #ECF9F9;

// Ant UI Colours
$ant-warning-yellow:                #FFE58F;
$ant-warning-yellow-light:          #FFFBE6;
$ant-success-green:                 #B7EB8F;
$ant-success-green-light:           #F6FFED;

$fa-legacy-title-grey:              #6D6D6D;
$fa-legacy-page-header:             #113533;
$fa-legacy-page-header-enterprise:  #122F44;


// Green button used in scheduler
$scheduler-success-button-border:           #639642;
$scheduler-success-button-gradient:         linear-gradient(to bottom, rgba(140, 181, 111, 1) 0, rgba(132, 181, 100, 1) 6%, rgba(126, 173, 93, 1) 12%, rgba(114, 172, 85, 1) 18%, rgba(114, 169, 76, 1) 24%, rgba(103, 163, 67, 1) 35%, rgba(103, 160, 57, 1) 41%, rgba(91, 158, 53, 1) 53%, rgba(93, 156, 51, 1) 59%, rgba(91, 155, 45, 1) 65%, rgba(89, 155, 49, 1) 71%, rgba(91, 155, 45, 1) 76%, rgba(87, 154, 48, 1) 82%, rgba(89, 152, 48, 1) 100%);
$scheduler-success-button-hover-gradient:   linear-gradient(to bottom, #5CAD3E 0, #58A53C 100%);

// Planning Toolbar Action Button
$scheduler-action-button-gradient:          linear-gradient(to bottom, #F5F5F5, #E5E5E5);

// Planning Toolbar Bulk Select Button
$scheduler-button-bg-active:                    #4483DE;
$scheduler-bulk-select-button-bg:               #E0E0E0;
$scheduler-bulk-select-button-bg-hover:         #DDE8F2;
$scheduler-bulk-select-button-text:             #3F3F3F;
$scheduler-bulk-select-button-text-disabled:    #666666;
$scheduler-bulk-select-button-text-hover:       #395E9B;
$scheduler-button-shadow-hover:                 #3A6ABF;

// Planning Mode Disabled Button
$scheduler-button-bg-disabled:                  #D3D3D3;
$scheduler-button-text-disabled:                #3F3F3F;
$scheduler-button-border-disabled:              #C7C7C7;

// Planning Mode Warning
$planning-mode-warning:                         #FE323E;

// Branch Borrowing
$branch_borrow_grey:                            #637186;
