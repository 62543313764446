@mixin has-internal-borders-only {
    &.has-internal-borders-only {
        .ant-table-body {
            // top and left borders & outer border radius
            > table {
                border-radius: 0;
                border: 0; }

            // inner border radius
            .ant-table-thead > tr:first-child > th:last-child,
            .ant-table-thead > tr:first-child > th:first-child {
                border-radius: 0; }

            // right border on last cell in row
            .ant-table-thead > tr > th:last-child,
            .ant-table-tbody > tr > td:last-child {
                border-right: 0; }

            // bottom border on cells in last row
            .ant-table-tbody > tr:last-child > td {
                border-bottom: 0; } } } }
