@import '../../colours.sass';
@import '../../sizes.sass';

$border-radius: 6px;

@mixin top-border-radius {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius; }

@mixin thin-scrollbar {
    // Firefox
    scrollbar-color: $scroll-thumb $scroll-track !important;
    scrollbar-width: thin !important;

    // Chrome
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px; }

    &::-webkit-scrollbar-thumb {
        background-color: $scroll-thumb; }

    &::-webkit-scrollbar-track {
        background-color: $scroll-track; } }

@mixin warning-popover {
    .ant-popover-inner-content {
        padding: 0;

        .ant-alert {
            font-size: 12px;
            padding: 8px;

            .fa-ant-icon {
                cursor: pointer;
                position: absolute;
                font-size: 18px;
                opacity: 0.6;
                right: 6px;
                transition: opacity 0.2s ease;

                &:hover {
                    opacity: 1; } } } }

    .ant-popover-arrow {
        border-right-color: $ant-warning-yellow-light !important;
        border-bottom-color: $ant-warning-yellow-light !important;
        z-index: 1; } }

@mixin popover-table {
    table {
        table-layout: fixed;
        border-collapse: collapse;
        text-align: left;
        width: 100%;
        margin-bottom: 0;

        td {
            vertical-align: top;

            &:first-child {
                font-weight: bold; } } } }

@mixin plain-list {
    padding: 0;
    list-style: none;
    margin-bottom: 0;

    li {
        margin-left: 0;
        font-size: $compact_body2; } }
