@import '../../../../styles/mixins/fa-footer-button.mixin.sass';

.ant-table-footer {
    .fa-data-table-with-footer--footer {
        text-align: right;

        .fa-select-input {
            margin-right: 15px;
            max-width: 200px; } } }

.is-compact {
    .ant-table-footer {
        padding: 10px;

        .fa-data-table-with-footer--footer {
            .fa-select-input {
                font-size: 12px; }

            .fa-ant-button {
                @include fa-footer-button-styles; }

            .is-enterprise {
                > .fa-ant-button {
                    @include fa-footer-button-enterprise-styles; } } } } }
