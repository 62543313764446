@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-branch-access-warning {
    &.ant-alert.ant-alert-warning {
        @include hd-text;
        background: $branch_borrow_grey;
        border-color: $branch_borrow_grey;
        font-size: $compact_body2;
        padding: 8px 12px;

        &.has-horizontal-borders-only {
            border-radius: 0;
            border-width: 1px 0; }

        .fa-branch-access-warning--message {
            color: $white;
            display: flex;

            .fa-branch-colour-badge {
                box-sizing: border-box;
                border: 1px solid $white;
                border-radius: 2px;
                width: 10px;
                margin-right: 4px; }

            > section {
                &:first-child {
                    margin-right: 8px; } } } } }
