@import '../../styles/colours.sass';

.fa-Countdown-TextArea {
    .fa-Countdown-TextArea--characters-remaining {
        float: right;
        color: $grey-b; }

    .is-overflown {
        float: right;
        color: $danger !important; } }
