@import '../../styles/partials/utils/typographyHelpers.sass';
@import '../../styles/colours.sass';

.fa-scheduler-filters-and-layouts {
    .ant-modal-body {
        padding-top: 0; }

    .fa-scheduler-filters-and-layouts--tab-body {
        height: 440px;
        display: flex;

        .fa-scheduler-filters-and-layouts--tab-panel {
            hr {
                margin: 2em 0;
                border: 0;
                border-top: 1px solid $grey-e; }

            &:not(:last-child) {
                margin-right: 4em; } } }

    .fa-scheduler-filters-and-layouts--filter-panel-header {
        display: flex;
        width: 540px;
        margin-bottom: 8px;

        > h4 {
            margin-bottom: 0; }

        .fa-scheduler-filters-and-layouts--filter-panel-header-switch {
            display: flex;
            margin-left: auto;
            font-size: 12px;
            align-items: center;

            > span {
                margin-left: 0.5em;
                position: relative;
                top: 1px; } } }

    h4 {
        @include system-font;
        color: $text-header;
        text-transform: none;
        font-size: 12px;
        font-weight: 500; } }
