@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-timesheet-info {
    @include compact-font-size;
    background: $grey-f;
    border: 1px solid $grey-e;
    border-radius: 4px;
    padding: 1em;
    display: grid;
    grid-template-columns: 50% 50%;

    > section {
        &:first-child {
            border-right: 2px solid $grey-e;
            margin-right: 1em; }

        > strong {
            display: block;
            font-size: 13px;
            margin-bottom: 0.5em; } } }

