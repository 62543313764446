@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';
@import '../../styles/partials/utils/componentHelpers.sass';

.fa-back-to-fieldaware-button {
    display: flex;
    align-items: center;

    &.is-compact {
        font-size: $compact_body_1; }

    > a {
        color: $text-body;
        text-decoration: none; }

    .ant-dropdown-trigger {
        cursor: pointer; }

    .ant-dropdown-open {
        color: $enterprise-primary; }

    .fa-ant-icon {
        margin-top: 1px; } }


.ant-dropdown-menu {
    a {
        text-decoration: none; } }
