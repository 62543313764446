@import '../../styles/colours.sass';

$very-small-size: 27px;
$small-size: 32px;
$default-size: 44px;
$large-size: 60px;

@mixin size($size) {
    width: $size;
    height: $size; }

@keyframes border-pulsate {
    0% {
        border-color: transparentize($white, 1); }
    50% {
        border-color: transparentize($white, 0); }
    100% {
        border-color: transparentize($white, 1); } }

.fa-job-state-badge {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $white;
    @include size($default-size);

    &.has-border {
        border: 1px solid $white; }

    &.has-update {
        border: 3px solid;
        animation: border-pulsate 3s infinite; }

    &.is-job-state-active {
        .fa-job-state-icon {
            transform: translateX(2px); } }

    &.is-job-state-scheduled, &.is-job-state-paused {
        .fa-job-state-icon {
            transform: translateX(0.5px); } }

    &.is-size-very-small {
        @include size($very-small-size); }

    &.is-size-small {
        @include size($small-size); }

    &.is-size-large {
        @include size($large-size);

        &.is-job-state-active {
            .fa-job-state-icon {
                transform: translateX(3px); } } }

    &.is-disabled {
        color: $text-disabled; } }
