@mixin fa-radio-button ($colour) {
    .ant-radio-inner {
        box-sizing: border-box; }

    .ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-focused .ant-radio-inner {
        border-color: $colour; }

    .ant-radio-checked {
        &:after {
            box-sizing: border-box;
            border-color: $colour; }

        &:not(.ant-radio-disabled) {
            .ant-radio-inner {
                border-color: $colour;

                &:hover {
                    border-color: $colour; }

                &:after {
                    background-color: $colour; } } } } }

