@import '../../styles/colours.sass';

@mixin fa-steps($colour) {
    .ant-steps-item {
        .ant-steps-item-icon {
            border-color: $colour;

            .ant-steps-icon {
                color: $colour; } }

        .ant-steps-item-content {
            .ant-steps-item-title {
                &:after {
                    background-color: $colour; } } }

        &.ant-steps-item-process {
            .ant-steps-item-icon {
                background-color: $colour;

                .ant-steps-icon {
                    color: $white; } } } } }

.fa-steps {
    &:not(.is-enterprise) {
        @include fa-steps($primary); }

    &.is-enterprise {
        @include fa-steps($enterprise-primary); }

    &.is-rebrand {
        @include fa-steps($rebrand); } }
