@import '../../styles/colours.sass';

@mixin checkbox-styles-definition($colour) {
    &:hover {
        .ant-checkbox-inner {
            border-color: $colour; } }

    .ant-checkbox {
        &:hover {
            .ant-checkbox-inner {
                border-color: $colour; } }

        .ant-checkbox-input {
            &:focus {
                + .ant-checkbox-inner {
                    border-color: $colour; } } } }

    .ant-checkbox-checked {
        &:after {
            border: 1px solid $colour; }

        .ant-checkbox-inner {
            border-color: $colour;
            background-color: $colour; } } }

@mixin fa-checkbox-styles {
    @include checkbox-styles-definition($primary); }

@mixin fa-checkbox-enterprise-styles {
    @include checkbox-styles-definition($enterprise-primary); }

@mixin fa-checkbox-rebrand-styles {
    @include checkbox-styles-definition($rebrand); }
