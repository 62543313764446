@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-notifications {
    @include hd-text;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-right: 4px;
    border: none;
    border-radius: 50%;
    font-size: 16px;
    z-index: 999; // needs to be above quick view

    .fa-notifications--inner {
        color: $white;
        cursor: pointer;
        user-select: none;
        display: flex;
        width: 26px;
        height: 26px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: $primary-opaque-light-2;
        transition: background 0.1s;

        &:hover {
            background: $primary-opaque-dark; }

        &:active {
            background: $primary-opaque-light-2; }

        .ant-badge-count {
            box-sizing: border-box;
            font-size: 10px;
            height: 14px;
            line-height: 14px;
            padding: 0 4px; }

        .anticon-bell {
            transform: rotate(35deg);
            font-size: 16px; } }

    &.is-enterprise {
        .fa-notifications--inner {
            background: $enterprise-primary-opaque-light-2;

            &:hover {
                background: $enterprise-primary-opaque-dark; }

            &:active {
                background: $enterprise-primary-opaque-light-2; } } }

    &.is-rebrand {
        .fa-notifications--inner {
            background: $rebrand-opaque-light-2;

            &:hover {
                background: $rebrand-opaque-dark; }

            &:active {
                background: $rebrand-opaque-light-2; } } } }

.fa-notifications--push-notification {
    padding-left: 16px;
    width: 360px;

    .fa-branch-colour-badge {
        margin-right: 4px; }

    .ant-notification-notice-with-icon {
        .ant-notification-notice-message,
        .ant-notification-notice-description {
            margin-left: 34px; }

        .ant-notification-notice-message {
            box-sizing: border-box;
            width: 90%;
            margin-bottom: 0;

            .fa-notifications--push-notification-header {
                display: flex;

                .fa-hoc-wrapper,
                .fa-notifications--push-notification-deleted-entity {
                    font-size: 12px;
                    margin-left: auto; } } } } }
