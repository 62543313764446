@import '../../styles/colours.sass';
@import '../../styles/partials/inputs';

.fa-hours-time-picker {
    .ant-time-picker-input {
        font-family: inherit;
        box-sizing: border-box;

        &:not([disabled]) {
            &:hover {
                border-color: $primary; }

            &:focus {
                border-color: $primary;
                box-shadow: 0 0 0 2px $primary-opaque-light-2; } } }

    &.ant-time-picker-large {
        width: 90px;

        &.is-12-hour-output {
            width: 112px; } }

    &.ant-time-picker-default {
        width: 85px;

        &.is-12-hour-output {
            width: 100px; } }

    &.ant-time-picker-small {
        width: 70px;

        &.is-12-hour-output {
            width: 92px; }

        .ant-time-picker-input {
            font-size: 12px; } } }

.is-enterprise {
    > .fa-hours-time-picker {
        .ant-time-picker-panel-select {
            li:focus {
                color: $enterprise-primary; } }

        .ant-time-picker-input:not([disabled]) {
            &:hover {
                border-color: $enterprise-primary; }

            &:focus {
                border-color: $enterprise-primary;
                box-shadow: 0 0 0 2px $enterprise-primary-opaque-light-2; } } } }

.is-rebrand {
    > .fa-hours-time-picker {
        .ant-time-picker-panel-select {
            li:focus {
                color: $rebrand; } }

        .ant-time-picker-input:not([disabled]) {
            &:hover {
                border-color: $rebrand; }

            &:focus {
                border-color: $rebrand;
                box-shadow: 0 0 0 2px $rebrand-opaque-light-2; } } } }

.fa-hours-time-picker-popup {
    input {
        font-size: inherit; }

    .ant-time-picker-panel-select {
        li {
            &:hover {
                background: $primary-opaque-light-1; }

            &:focus {
                color: $primary; } } }

    &.is-enterprise {
        .ant-time-picker-panel-select {
            li {
                &:hover {
                    background: $enterprise-primary-opaque-light-1; }

                &:focus {
                    color: $enterprise-primary; } } } }

    &.is-rebrand {
        .ant-time-picker-panel-select {
            li {
                &:hover {
                    background: $rebrand-opaque-light-1; }

                &:focus {
                    color: $rebrand; } } } }

    &.is-compact {
        .ant-time-picker-panel-select {
            li {
                font-size: 12px; } } } }
