.fa-datetime {
    &.is-compact {
        font-size: 12px;

        input {
            font-size: 12px; }

        .fa-hours-time-picker {
            width: 78px;

            &.is-12-hour-output {
                width: 100px; } }

        .fa-date-picker {
            width: 120px; } } }
