@import '../../styles/colours.sass';

.fa-spinner {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    &.is-grey {
        color: $text-disabled; }

    &:not(.is-grey) {
        color: $primary;

        &.is-enterprise {
            color: $enterprise-primary; }

        &.is-rebrand {
            color: $rebrand; } }

    &.fa-spinner-size-small {
        font-size: 16px; }

    &.fa-spinner-size-default {
        font-size: 1em; }

    &.fa-spinner-size-large {
        font-size: 72px; } }

