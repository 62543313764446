@import '../../styles/colours.sass';
@import '../../styles/mixins/fa-anchor.mixin.sass';

a.fa-anchor {
    @include fa-anchor($primary);

    &.is-plain-text {
        @include fa-anchor($text-body);
        &:hover {
            color: $primary-light !important; }

        &:active {
            color: $primary-dark !important; } } }

.is-enterprise {
    > a.fa-anchor {
        @include fa-anchor($enterprise-primary);

        &.is-plain-text {
            @include fa-anchor($text-body);
            &:hover {
                color: $enterprise-primary-light !important; }

            &:active {
                color: $enterprise-primary-dark !important; } } } }

.is-rebrand {
    > a.fa-anchor {
        @include fa-anchor($rebrand);

        &.is-plain-text {
            @include fa-anchor($text-body);
            &:hover {
                color: $rebrand-light !important; }

            &:active {
                color: $rebrand-dark !important; } } } }
