@import '../partials/utils/typographyHelpers.sass';

@mixin fa-tooltip {
    max-width: 300px;

    @include system-font;
    .ant-tooltip-inner {
        font-size: 12px;
        word-break: normal;
        min-height: unset; } }
