// Define standard font sizes for component typography
// See: https://fieldaware.atlassian.net/wiki/spaces/DG/pages/1730445327/Webapp+Typography+for+Ant+Design+Components

// Typography for components in pure Ant Design pages
$h1:                    96px;
$h2:                    60px;
$h3:                    48px;
$h4:                    34px;
$h5:                    24px;
$h6:                    20px;
$subtitle_1:            16px;
$subtitle_2:            14px;
$body_1:                16px;
$body_2:                14px;
$button:                14px;
$caption:               12px;
$overline:              10px;

// Compact typography for components in mixed Ant/Dojo pages
$compact_h1:            96px;
$compact_h2:            60px;
$compact_h3:            48px;
$compact_h4:            34px;
$compact_h5:            24px;
$compact_h6:            19px;
$compact_subtitle_1:    14px;
$compact_subtitle_2:    12px;
$compact_body_1:        16px;
$compact_body2:         12px;
$compact_button:        12px;
$compact_caption:       10px;
$compact_overline:      12px;
