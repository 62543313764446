.ant-collapse {
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fafafa;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child {
  border-radius: 0 0 4px 4px;
}
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  line-height: 22px;
  padding: 12px 0 12px 40px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  font-size: 12px;
  position: absolute;
  display: inline-block;
  line-height: 46px;
  vertical-align: top;
  -webkit-transition: -webkit-transform 0.24s;
  transition: -webkit-transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
  top: 0;
  left: 16px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow:before {
  display: block;
  font-family: "anticon" !important;
  content: "";
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}

.ant-collapse-anim-active {
  -webkit-transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ant-collapse-content {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}

.ant-collapse-content-inactive {
  display: none;
}

.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 4px 4px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header[aria-expanded="true"] .arrow {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ant-collapse-borderless {
  background-color: #fff;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}

.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}

.ant-table-row-expand-icon {
  position: relative;
}
.ant-table-row-expand-icon.ant-table-row-collapsed, .ant-table-row-expand-icon.ant-table-row-expanded {
  border: none;
  background: none;
}
.ant-table-row-expand-icon.ant-table-row-collapsed:after {
  content: "";
  font-family: "anticon" !important;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  border: none;
  right: 16px;
  top: 0;
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1.5;
  transform: scale(0.66667) rotate(0deg);
}
.ant-table-row-expand-icon.ant-table-row-expanded:after {
  content: "";
  font-family: "anticon" !important;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  border: none;
  right: 16px;
  top: 0;
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1.5;
  transform: rotate(180deg) scale(0.75);
}

.no-expand .ant-table-row-expand-icon.ant-table-row-collapsed {
  display: none;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding-left: 4px;
}

li.ant-select-dropdown-menu-item {
  margin-left: 0px;
  text-align: left;
}

th.cf-expand, td.cf-expand {
    text-align: center !important
}

.fa-table-list div div table {
    border-collapse: separate;
    border-spacing: 0;
}

.fa-table-list tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

.fa-table-list tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}
