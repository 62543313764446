.fa-add-remove-table {
    .ant-table-thead {
        th:not(:last-child) {
            border-right: none; } }

    .ant-table-tbody {
        td:not(:last-child) {
            border-right: none; }

        .fa-add-remove-table--action-column {
            text-align: right; } }

    .ant-table-placeholder {
        text-align: left; } }
