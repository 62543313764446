@import '../../styles/colours.sass';

.fa-control-card {
    background: $grey-f;
    border: 1px solid $grey-e;
    border-radius: 4px;
    padding: 1em;

    header {
        display: flex;
        margin-bottom: 0.5em; }

    strong {
        font-weight: 600;
        font-size: 13px; } }
