@import '../../styles/mixins/fa-date-picker.mixin.sass';

.fa-ant-dateRange {
    @include fa-datepicker-styles; }

.fa-ant-dateRange-dropdown {
    @include fa-datepicker-dropdown-styles;
    .ant-calendar-header {
        > div {
            > a {
                display: flex;
                align-items: center; } } }

    &.is-enterprise {
        @include fa-datepicker-dropdown-enterprise-styles; }

    &.is-rebrand {
        @include fa-datepicker-dropdown-rebrand-styles; } }

.is-enterprise {
    .fa-ant-dateRange {
        @include fa-datepicker-enterprise-styles; } }

.is-rebrand {
    .fa-ant-dateRange {
        @include fa-datepicker-rebrand-styles; } }
