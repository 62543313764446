@import '../../styles/colours.sass';
@import '../../styles/mixins/fa-scheduler-button.mixin.sass';

.fa-planning-mode-toolbar {
    height: 65px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $ghost-white;
    padding: 10px;
    pointer-events: auto;
    border: 1px solid $grey-c;
    border-bottom: 2px solid $grey-4;

    &.is-hidden {
        display: none; }

    .fa-planning-mode-toolbar--loading-message {
        display: flex;
        align-items: center;
        margin: 0 auto;
        font-size: 24px; }

    .fa-planning-mode-toolbar--section {
        display: flex;
        flex: 1;

        &:nth-child(2) {
            justify-content: center; }

        &:last-child {
            justify-content: flex-end; }

        .container {
            display: flex;

            &.is-vertical {
                flex-direction: column;
                height: 44px;
                margin: 0 4px;
                justify-content: space-between; }

            &.is-hidden {
                display: none; }

            &.smart-scheduler-response-container {
                align-items: center;
                margin-right: 10px;

                img.warning {
                    display: none; }

                a.job-list-trigger-node {
                    margin-left: 10px;

                    &:hover {
                        color: $grey-6 !important; }

                    &.is-hidden {
                        display: none; } }

                &.is-warning {
                    img.success {
                        display: none; }

                    img.warning {
                        display: initial; } } }

            .toolbar-inline-icon-container {
                width: 24px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-right: 4px; } } }

    &.is-loading {
        background: $ant-success-green-light;
        border-color: $ant-success-green;
        border-bottom-color: darken($ant-success-green, 10%);

        &.is-discarding {
            background: $ant-warning-yellow-light;
            border-color: $ant-warning-yellow;
            border-bottom-color: darken($ant-warning-yellow, 10%); } }

    .fa-planning-mode-toolbar--bulk-select-container {
        display: flex;
        flex-direction: column;
        height: 50px;
        margin: 0 4px;
        justify-content: space-between;

        .fa-planning-mode-toolbar--result-message {
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .fa-svg-icon-rounded-warning {
                font-size: 14px;
                color: $planning-mode-warning;
                vertical-align: sub;
                margin-right: 4px; }

            .fa-image {
                position: absolute; }

            // The text after the image
            .fa-image + span {
                margin-left: 30px; }

            .fa-anchor {
                color: $text-header !important;
                margin-left: 8px;
                user-select: none;
                cursor: pointer;

                &:hover {
                    opacity: 0.7; } } }

        .fa-planning-mode-toolbar--rescheduling-actions {
            display: flex;

            // one child -> center
            > * {
                margin: auto; }

            // two children -> space between
            > * + * {
                margin-left: 4px; } } } }

.fa-planning-mode-toolbar--button-group {
    display: flex;
    margin: 0 10px;

    // Buttons in the group
    > * {
        &:first-child {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0; }

        &:nth-child(2) {
            border-radius: 0; }

        &:last-child {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0; } } }

.fa-planning-mode-toolbar--save-button {
    @include scheduler-button;
    background-image: $scheduler-success-button-gradient;
    border: 1px solid $scheduler-success-button-border;
    transition: opacity 0.2s;

    &:not(.is-disabled) {
        &:hover {
            background-image: $scheduler-success-button-hover-gradient; }

        &:active, &:focus {
            box-shadow: inset rgba(0, 0, 0, 0.5) 0 0 6px; } }

    &.is-disabled {
        opacity: 0.6;
        border: 1px solid $grey-c;
        color: $grey-3;
        background: $grey-d;
        box-shadow: none;
        cursor: default; } }

.fa-planning-mode-toolbar--cancel-button {
    @include scheduler-button;
    background: none;
    border: 1px solid transparent;
    color: inherit;
    box-shadow: none;
    text-decoration: underline;
    text-shadow: none;
    line-height: 26px;
    transition: opacity 0.2s;

    &:not(.is-disabled) {
        &:hover {
            background: $grey-e;
            box-shadow: none; }

        &:active, &:focus {
            background: $grey-d;
            border: 1px solid $grey-c; } }

    &.is-disabled {
        opacity: 0.4;
        cursor: default; } }



