@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-rescheduling-results {
    @include system-font;
    .ant-modal-body {
        > h3 {
            color: $text-subtitle;
            font-weight: normal; }

        > section {
            margin-bottom: 1em; } }

    .fa-rescheduling-results--expand-row-icon {
        cursor: pointer;
        padding: 4px;
        opacity: 0.6;
        transition: opacity 0.4s;

        &:hover {
            opacity: 1; } }

    .fa-rescheduling-results--status-cell {
        display: flex;

        > aside {
            font-size: 14px;
            margin-right: 4px;
            padding-top: 2px; } }

    .fa-data-table.is-compact {
        tr.ant-table-expanded-row {
            > td {
                padding: 0;

                > .ant-table-wrapper {
                    margin: unset; } } } } }
