@import '../../styles/colours.sass';

@mixin datepicker-styles-definition($colour, $colour-light, $shadow-colour) {
    &:hover, &:focus {
        .ant-calendar-picker-input:not(.ant-input-disabled),
        .ant-input:not(.ant-input-disabled) {
            border-color: $colour;
            box-shadow: 0 0 0 2px $shadow-colour; } }

    .ant-calendar-picker-input {
        &:focus {
            box-shadow: 0 0 0 2px $shadow-colour;
            border-color: $colour-light; }

        .ant-calendar-picker-clear, .ant-calendar-picker-icon {
            position: absolute; }

        &:focus {
            .ant-calendar-picker-input:not(.ant-input-disabled) {
                box-shadow: 0 0 0 2px $shadow-colour;
                border-color: $colour-light; } }

        .ant-calendar-range-picker-input {
            &:focus, &:active {
                box-shadow: none; } } } }

@mixin fa-datepicker-styles {
    @include datepicker-styles-definition($primary, $primary-light, $primary-opaque-light-1); }

@mixin fa-datepicker-enterprise-styles {
    @include datepicker-styles-definition($enterprise-primary, $enterprise-primary-light, $enterprise-primary-opaque-light-1); }

@mixin fa-datepicker-rebrand-styles {
    @include datepicker-styles-definition($rebrand, $rebrand-light, $rebrand-opaque-light-1); }

// DROPDOWN STYLES
@mixin datepicker-dropdown-styles-definition($colour, $colour-light, $opaque-light-1) {
    .ant-calendar-header {
        .ant-calendar-prev-year-btn, .ant-calendar-prev-month-btn, .ant-calendar-month-select, .ant-calendar-year-select, .ant-calendar-next-month-btn, .ant-calendar-next-year-btn,
        .ant-calendar-year-panel-prev-decade-btn, .ant-calendar-year-panel-decade-select, .ant-calendar-year-panel-next-decade-btn, .ant-calendar-decade-panel-prev-century-btn, .ant-calendar-decade-panel-next-century-btn, .ant-calendar-month-panel-prev-year-btn, .ant-calendar-month-panel-year-select, .ant-calendar-month-panel-next-year-btn {
            &:hover {
                color: $colour-light; } } }

    .ant-calendar-body {
        .ant-calendar-table {
            .ant-calendar-cell {
                &.ant-calendar-selected-date {
                    .ant-calendar-date {
                        background: $colour !important; } } }

            .ant-calendar-in-range-cell:before {
                background: $opaque-light-1; }

            .ant-calendar-date:hover {
                background: $opaque-light-1; }

            .ant-calendar-today {
                .ant-calendar-date {
                    border-color: $colour;
                    color: $colour; }

                &.ant-calendar-selected-date {
                    .ant-calendar-date {
                        color: $white !important;
                        background: $colour !important; } } }

            .ant-calendar-selected-day {
                .ant-calendar-date {
                    background: $colour !important;
                    color: $white !important; } } } }

    .ant-calendar-year-panel-body {
        .ant-calendar-year-panel-year:hover {
            color: $colour-light;
            background: $opaque-light-1; }

        .ant-calendar-year-panel-selected-cell {
            .ant-calendar-year-panel-year {
                background: $colour;

                &:hover {
                    color: $white; } } } }

    .ant-calendar-decade-panel-body {
        .ant-calendar-decade-panel-decade:hover {
            color: $colour-light;
            background: $opaque-light-1; }

        .ant-calendar-decade-panel-selected-cell {
            .ant-calendar-decade-panel-decade {
                background: $colour;
                &:hover {
                    color: $white; } } } }

    .ant-calendar-month-panel-body {
        td:not(.ant-calendar-month-panel-cell-disabled) {
            .ant-calendar-month-panel-month:hover {
                color: $colour-light;
                background: $opaque-light-1; } }

        .ant-calendar-month-panel-selected-cell {
            .ant-calendar-month-panel-month {
                background: $colour;

                &:hover {
                    color: $white !important;
                    background: $colour !important; } } } }

    .ant-calendar-month-panel-body {
        .ant-calendar-month-panel-cell-disabled {
            a.ant-calendar-month-panel-month {
                color: $grey-b !important; } }

        .ant-calendar-month-panel-cell:not(.ant-calendar-month-panel-cell-disabled) {
            a.ant-calendar-month-panel-month {
                color: initial; }

            a.ant-calendar-month-panel-month:hover {
                color: $colour-light !important;
                background: $opaque-light-1; } }

        .ant-calendar-month-panel-cell.ant-calendar-month-panel-selected-cell {
            a.ant-calendar-month-panel-month {
                background: $colour;
                color: $white !important;
                &:hover {
                    color: $white;
                    background: $colour !important; } } } }

    .ant-calendar-footer {
        .ant-calendar-today-btn {
            cursor: pointer;
            color: $colour;

            &:hover {
                text-decoration: underline; } } }

    &.is-enterprise {
        .ant-calendar-range {
            input.ant-calendar-input {
                &:hover, &:focus, &:active {
                    box-shadow: none; } } } } }

@mixin fa-datepicker-dropdown-styles {
    @include datepicker-dropdown-styles-definition($primary, $primary-light, $primary-opaque-light-1); }

@mixin fa-datepicker-dropdown-enterprise-styles {
    @include datepicker-dropdown-styles-definition($enterprise-primary, $enterprise-primary-light, $enterprise-primary-opaque-light-1); }

@mixin fa-datepicker-dropdown-rebrand-styles {
    @include datepicker-dropdown-styles-definition($rebrand, $rebrand-light, $rebrand-opaque-light-1); }
