@import '../../styles/colours.sass';
@import '../../styles/shadows.sass';
@import '../../styles/partials/utils/componentHelpers.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

$add-button-size: 20px;

.fa-filter-tags {
    @include hd-text;
    font-size: 12px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    background: $ghost-white;
    transition: opacity 0.3s ease-in-out;

    .ant-divider-vertical {
        height: 1.5em; }

    &.is-loading {
        opacity: 0.6;
        pointer-events: none; }

    .fa-filter-tags--title-container {
        margin-right: 10px;
        min-width: max-content;
        box-sizing: border-box; }

    .fa-scroll-container {
        height: 100%;
        overflow-x: hidden;
        flex-shrink: 1;

        .fa-filter-tags--tag {
            color: $text-header;
            padding: 2px 4px;
            cursor: pointer;
            user-select: none;
            background: linear-gradient(rgb(255, 255, 255) 25%, rgba(230, 230, 230, 0.79) 100%);
            --antd-wave-shadow-color: transparent;

            .fa-filter-tags--tag-label {
                display: inline-block;
                margin-right: -2px;
                transform: translateX(4px);
                transition: transform 0.1s ease-in-out;

                .fa-filter-tags--tag-count {
                    margin-right: 6px;
                    color: $text-body;
                    font-weight: bold; } }

            .anticon-close {
                color: $text-body;
                opacity: 0; }

            &:hover {
                .fa-filter-tags--tag-label {
                    transform: translateX(0); }

                .anticon-close {
                    opacity: 1; } } } }

    .fa-filter-tags--button-container {
        display: flex;
        align-items: center;
        font-size: 15px;
        margin-left: 8px;
        width: $add-button-size;
        height: $add-button-size;

        .fa-filter-tags--add-button {
            > span {
                font-size: 12px;
                width: $add-button-size;
                height: $add-button-size;
                line-height: $add-button-size;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $grey-9;
                color: $white;
                transition: transform 0.2s, background-color 0.2s, box-shadow 0.3s cubic-bezier(.25, .8, .25, 1);
                @include material-shadow-1;

                &:hover {
                    background: $grey-8; } }

            &.is-open {
                > span {
                    background: $enterprise-primary;
                    transform: rotate(45deg);
                    @include material-shadow-2; } } } } }

.fa-filter-tags--popover {
    width: unset;

    .fa-filter-tags--popover-content {
        display: flex;
        align-items: center;

        .ant-btn {
            line-height: unset; }

        .fa-filter-tags--form-fields {
            width: 360px;
            display: flex;
            justify-content: space-between;
            margin-right: 0.5em; }

        .fa-filter-tags--colour-picker-container {
            align-self: center;
            margin-right: 0.5em; }

        .fa-filter-tags--popover-close-container {
            cursor: pointer;
            font-size: 18px;
            margin-left: 0.5em;
            opacity: 0.8;
            transition: opacity 0.3s ease-in-out;

            &:hover {
                opacity: 1; } } }

    &.has-warning {
        .fa-filter-tags--popover-warning {
            font-size: 12px;
            display: flex;
            flex-direction: column;

            strong {
                font-weight: 600;
                padding-bottom: 0.5em;
                margin-bottom: 0.5em;
                border-bottom: 1px solid $grey-e; }

            .fa-ant-icon {
                margin-right: 4px;
                position: relative;
                top: 2px; } } } }
