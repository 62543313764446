@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';
@import '../../styles/partials/utils/componentHelpers.sass';

.fa-scheduler-job-tooltip--overlay {
    @include hd-text;
    font-size: $compact_body2;
    max-width: 400px;

    .ant-tooltip-arrow {
        border-right-color: $white;
        border-bottom-color: $white; }

    .ant-tooltip-inner {
        padding: 0 0 8px 0;
        color: $text-body;
        background: $white;
        box-sizing: border-box;
        @include popover-table;

        .fa-branch-access-warning {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; }

        main {
            padding: 8px 12px 0 12px;

            strong {
                font-weight: bold; }

            i {
                font-style: italic; } } } }
