@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-timesheet-settings {
    @include system-font;
    @include compact-font-size;

    .fa-section-title {
        font-size: 16px;
        color: $text-body;
        margin-bottom: 10px;

        small {
            font-size: 12px;
            margin-left: auto; } }

    p {
        margin-bottom: 14px; }

    .ant-input-number {
        width: 50px; } }
