@import '../partials/utils/typographyHelpers.sass';
@import '../colours.sass';

@mixin fa-anchor ($colour) {
    @include system-font;

    text-decoration: none;
    transition: color 0.1s;

    &.is-plain-text {
        text-decoration: underline; }

    &[disabled] {
        color: $text-disabled !important;
        cursor: not-allowed;
        pointer-events: none;
        text-decoration: unset; }

    &:not(.is-plain-text) {
        &:not([href]) {
            text-decoration: unset; } }

    &:not([disabled]) {
        color: $colour !important;

        &:hover {
            text-decoration: underline; }

        @if $colour == $primary {
            &:active {
                color: $primary-dark !important; } }

        @else if $colour == $enterprise-primary {
            &:active {
                color: $enterprise-primary-dark !important; } } } }
