@import '../../styles/mixins/fa-checkbox.mixin.sass';

.fa-react-checkbox {
    @include fa-checkbox-styles; }

.is-enterprise {
    > .fa-react-checkbox {
        @include fa-checkbox-enterprise-styles; } }

.is-rebrand {
    > .fa-react-checkbox {
        @include fa-checkbox-rebrand-styles; } }
