@import '../../styles/colours.sass';

@mixin select-styles-definition($colour, $shadow-colour) {
    min-width: 180px;
    max-width: 100%;

    &:not(.ant-select-disabled) {
        .ant-select-selection {
            &:hover {
                border-color: $colour; }

            &:focus {
                border-color: $colour;
                box-shadow: 0 0 0 2px $shadow-colour; } }

        &.ant-select-open {
            .ant-select-selection {
                border-color: $colour;
                box-shadow: 0 0 0 2px $shadow-colour; } }

        .anticon-loading {
            color: $colour; } }

    &.ant-select-sm {
        font-size: 12px; } }


@mixin fa-select-styles {
    @include select-styles-definition($primary, $primary-opaque-light-2); }

@mixin fa-select-enterprise-styles {
    @include select-styles-definition($enterprise-primary, $enterprise-primary-opaque-light-2); }

@mixin fa-select-rebrand-styles {
    @include select-styles-definition($rebrand, $rebrand-opaque-light-2); }

// DROPDOWN STYLES
@mixin select-dropdown-styles-definition($colour, $hover-option-colour) {
    .ant-select-dropdown-menu-item-active {
        background-color: $hover-option-colour; }

    .ant-select-dropdown-menu-item {
        &:hover {
            background-color: $hover-option-colour; } }

    &.fa-dropdown-small {
        li {
            font-size: 12px;
            line-height: 18px; } }

    .anticon-loading-3-quarters {
        color: $colour; } }

@mixin fa-select-dropdown-styles {
    @include select-dropdown-styles-definition($primary, $primary-opaque-light-1); }

@mixin fa-select-dropdown-enterprise-styles {
    @include select-dropdown-styles-definition($enterprise-primary, $enterprise-primary-opaque-light-1); }

@mixin fa-select-dropdown-rebrand-styles {
    @include select-dropdown-styles-definition($rebrand, $rebrand-opaque-light-1); }
