@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-mock-scheduler-block {
    @include hd-text;
    @include one-line-of-text;
    cursor: pointer;
    color: $white;
    background-color: $job-state-scheduled;
    border: 1px solid $job-state-scheduled;
    font-size: 12px;
    height: 27px;
    line-height: 27px;
    width: 100px;
    padding: 0 4px;
    border-radius: 4px;
    box-sizing: content-box; }

.fa-mock-scheduler-block--tooltip {
    max-width: 530px;

    strong {
        font-weight: bold; }

    .ant-tooltip-inner {
        font-size: 12px;
        background: $white;
        color: $text-body;
        padding: 6px 8px;
        min-height: 0; }

    .ant-tooltip-arrow {
        left: 6px;
        border: 1px solid transparent;
        background: $white; } }
