@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

h1.fa-page-title {
    @include system-font;
    font-size: 24px;
    font-weight: 400;
    color: $text-header !important;
    margin-bottom: 0.5em;
    word-wrap: break-word;

    &.has-hd-text {
        @include hd-text; } }

hr.fa-page-title--rule {
    border-color: $text-disabled;
    border-style: solid;
    border-width: 1px 0 0;
    height: 0;
    margin-bottom: 20px; }
