@import '../../styles/colours.sass';

@mixin disabled-button {
    color: $text-disabled;
    cursor: not-allowed;
    background-color: $ghost-white;
    border-color: $grey-d;
    text-shadow: none;
    box-shadow: none; }

@mixin button-styles-definition($colour, $colour-light, $colour-dark) {
    &.ant-btn-sm {
        font-size: 12px; }

    &.ant-btn-lg {
        font-size: 16px; }

    &.ant-btn:not([disabled]):not(.is-ui-disabled) {
        &:not(.ant-btn-danger) {
            &:hover, &:focus {
                color: $colour;
                border-color: $colour; }

            &:active, &.active {
                color: $colour-dark;
                border-color: $colour-dark; }

            &.ant-btn-clicked:after {
                border-color: $colour; }

            &.ant-btn-primary {
                color: $white;
                background: $colour;
                border-color: $colour;

                &:hover, &:focus {
                    border-color: $colour-light;
                    background: $colour-light; }

                &:active, &.active {
                    border-color: $colour-dark;
                    background: $colour-dark; }

                &.ant-btn-background-ghost {
                    color: $colour;

                    &:hover, &:focus {
                        border-color: $colour-light;
                        color: $colour-light; }

                    &:active, &.active {
                        color: $colour-dark;
                        border-color: $colour-dark; }

                    &.ant-btn-clicked:after {
                        border-color: $colour; } } } }

        &.ant-btn-danger {
            border-color: $danger;
            background: $danger;
            color: $white;

            &:focus {
                border-color: $danger-dark;
                background: $danger-light; }

            &.ant-btn-background-ghost {
                color: $danger;

                &:hover, &:focus {
                    color: $danger-light; }

                &:active, &.active {
                    color: $danger-dark; } }

            &:hover {
                border-color: $danger-light;
                background: $danger-light; }

            &:active, &.active {
                border-color: $danger-dark;
                background: $danger-dark;
                color: $grey-f; }

            &.ant-btn-clicked:after {
                border-color: $danger; } } }

    &.ant-btn-link {
        // make the button look like an <a>
        font-size: 14px;
        color: $colour;
        border: none;
        background: none;
        display: inline;
        line-height: unset;
        height: unset;
        padding: unset;
        user-select: initial;
        text-decoration: unset;
        font-style: inherit;

        &.ant-btn-sm {
            font-size: 12px; }

        &.ant-btn-lg {
            font-size: 16px; }

        &:not([disabled]):not(.is-ui-disabled) {
            &:hover {
                color: $colour-light !important; }

            &:active {
                color: $colour-dark; }

            &:focus {
                text-decoration: underline; }

            &.ant-btn-clicked:after {
                border: none; } }

        &[disabled] {
            color: $text-disabled;
            cursor: default;

            &:hover {
                background: none; } } }

    &.ant-btn-dark {
        &:not([disabled]):not(.is-ui-disabled) {
            background-color: $text-body;
            border-color: $text-body;
            color: $grey-e;

            &.ant-btn-background-ghost {
                color: $text-body;
                border-color: $text-body;

                &:hover {
                    color: $text-header;
                    border-color: $text-header; }

                &:active, &:focus {
                    border-color: $text-subtitle;
                    color: $text-subtitle; } }

            &:hover {
                background-color: $text-group-header;
                border-color: $text-header;
                color: $white; }

            &:active, &:focus {
                background-color: $text-header;
                border-color: $text-header;
                color: $white; }

            &.ant-btn-clicked:after {
                background-color: $text-body; } } }

    &.is-ui-disabled {
        @include disabled-button;

        &:hover, &:active, &:focus {
            @include disabled-button; } } }

@mixin fa-button-styles {
    @include button-styles-definition($primary, $primary-light, $primary-dark); }

@mixin fa-button-enterprise-styles {
    @include button-styles-definition($enterprise-primary, $enterprise-primary-light, $enterprise-primary-dark); }

@mixin fa-button-rebrand-styles {
    @include button-styles-definition($rebrand, $rebrand-light, $rebrand-dark); }
