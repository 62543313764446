@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-table-title {
    @include system-font;
    padding-top: 3px;
    padding-left: 1px;
    color: $grey-8 !important;
    font-weight: 300;
    margin-bottom: 8px;
    margin-top: unset;

    &.is-compact {
        padding-top: 0;
        font-size: $compact_h6; }

    &.has-hd-text {
        @include hd-text; } }
