@import '../../../../styles/colours.sass';

.overtime-calculation {
    .overtime-calculation--options-container {
        display: flex;
        margin-bottom: 0.5em; }

    .overtime-calculation--option {
        background: $grey-f;
        border: 1px solid $grey-e;
        border-radius: 4px;
        padding: 1em;

        header {
            display: flex;
            margin-bottom: 0.5em;

            strong {
                font-weight: 600;
                font-size: 13px; } }

        main {
            margin-left: 2em;

            > div {
                margin-bottom: 0.5em;
                display: flex;
                flex-direction: column; } }

        &.is-empty {
            header {
                margin-bottom: 0; } }

        &.is-read-only {
            border: none;

            main {
                margin-left: 0; } }

        &:not(:last-child) {
            margin-right: 0.5em;
            width: 33%; }

        &:not(.is-read-only) {
            cursor: pointer;

            &:hover {
                background: $white;
                border-color: $grey-d; }

            &.is-selected {
                background: $white;
                border-color: $primary-light; }

            &:not(.is-selected) {
                color: $text-subtitle; }

            &.is-enterprise {
                &.is-selected {
                    border-color: $enterprise-primary-light; } } } } }
