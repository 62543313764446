@import '../../styles/colours.sass';

$row-height: 36px;

.fa-data-field-selector {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .fa-data-field-selector--data-field-row {
        display: flex;
        height: $row-height;
        line-height: $row-height;

        > div {
            &:first-child {
                display: none; } } }

    &.has-visible-label-column {
        .fa-data-field-selector--data-field-row {
            > div {
                &:first-child {
                    display: block;
                    max-width: 120px;
                    text-align: right;
                    margin-right: 0.5em;
                    overflow-x: hidden;
                    text-overflow: ellipsis; } } } }

    .fa-data-field-selector--clear-field {
        font-size: 16px;
        margin-left: 0.5em;
        color: $text-subtitle;
        transition: color 0.2s;

        &:hover {
            color: $text-header; }

        &.is-disabled {
            color: $text-disabled;
            pointer-events: none; } } }
