@import '../../styles/colours.sass';

.fa-notification-settings {
    .ant-modal-body {
        padding: 24px 0;

        > header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            margin-bottom: 10px;

            > h1 {
                display: inline;
                font-size: 16px;
                font-weight: normal;
                color: $text-body;
                margin: 0; } }

        > main {
            &:not(.is-compact) {
                height: 500px; }

            .fa-scroll-container {
                height: 100%;
                overflow-x: hidden;

                .ant-card {
                    margin: auto;
                    margin-bottom: 1em;
                    width: 750px;

                    &.fa-notification-settings--edit-card {
                        background: $selected-yellow; }

                    &:first-child {
                        margin-top: 10px; }

                    // this label gets a margin if it's outside the card section
                    .fa-overline-label {
                        margin-bottom: 4px; }

                    .fa-notification-settings--card-section {
                        display: flex;
                        justify-content: space-between;

                        &:not(:last-child) {
                            margin-bottom: 16px; }

                        &.is-compact {
                            margin-bottom: 4px;
                            align-items: flex-end; }

                        > .fa-notification-settings--job-state-title-label {
                            font-size: 16px;
                            font-weight: 500;
                            color: $text-header; }

                        // this label gets no margin if it's inside the card section
                        > .fa-overline-label {
                            margin-bottom: 0; } } } } } } }

.fa-notification-settings--job-state-dropdown-label,
.fa-notification-settings--job-state-title-label {
    display: flex;
    align-items: center;

    > * {
        &:first-child {
            margin-right: 8px; } } }

.fa-notification-settings--rules-header {
    display: flex;

    .fa-notification-settings--display-on-notification-section {
        margin-left: auto; }

    .fa-selectable-message {
        margin-bottom: 0;

        p {
            margin-bottom: 1em; } } }
