@mixin fa-input ($borderColour, $boxShadowColour) {
    &.ant-input-sm {
        font-size: 12px; }

    &:not(.ant-input-disabled) {
        &:hover {
            border-color: $borderColour; }

        &:focus {
            border-color: $borderColour;
            box-shadow: 0 0 0 2px $boxShadowColour; } } }
