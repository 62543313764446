@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

@mixin icon-with-hover ($main-colour, $hover-colour) {
    i {
        color: $main-colour; }

    &:hover {
        color: $hover-colour; } }

.fa-activity-feed {
    @include system-font;
    cursor: initial;
    position: fixed;
    top: 34px;
    right: 4px;
    text-align: left;

    > .ant-card {
        position: relative;
        width: 340px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

        li {
            margin-left: 0; }

        > .ant-card-head {
            .ant-card-head-title {
                padding: 12px 0; } }

        > .ant-card-body {
            max-height: 660px;
            overflow-y: auto;
            padding: 0;

            .fa-scroll-container {
                // 660px - 32px (.ant-card-body height) - (.fa-activity-feed--actions height)
                height: 628px;
                overflow-x: hidden;
                flex-shrink: 1; } }

        .ant-card-extra {
            .anticon-setting {
                font-size: 18px;
                margin-right: 12px; }

            .anticon-close {
                opacity: 1;

                &:hover {
                    color: $text-header; } }

            .fa-ant-icon {
                opacity: 0.85;
                font-size: 19px;
                transition: opacity 0.1s;

                &:hover {
                    opacity: 1; } } } }

    .fa-activity-feed--actions {
        position: sticky;
        top: 0;
        z-index: 1;
        height: 32px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        background: $ghost-white;
        border: 1px solid $grey-e;
        border-left-width: 0;
        border-right-width: 0;

        .fa-with-tooltip-hoc {
            height: 21px; }

        i {
            color: $text-body;
            opacity: 0.85;

            &:hover {
                opacity: 1; } }

        &.is-read {
            @include icon-with-hover($primary, $primary-dark);

            &.is-enterprise {
                @include icon-with-hover($enterprise-primary, $enterprise-primary-dark); } }

        &+.ant-alert-error {
            .ant-alert-message {
                font-size: 12px; } } }

    .fa-activity-feed-list-empty-item {
        text-align: center;

        .ant-card-body {
            > i {
                opacity: 0.6;
                font-style: italic; } }

        &.is-loading {
            opacity: 1;
            font-size: 2em;
            text-align: center; }

        .fa-ant-button.ant-btn-link {
            > span {
                text-decoration: underline; } } }

    .fa-activity-feed--end-of-list {
        display: flex;
        opacity: 0.6;
        width: 100%;
        padding: 16px 24px;
        font-size: 13px;
        font-style: italic;
        align-content: center;
        border-radius: 2px 2px 0 0;
        background: $ghost-white; } }
