@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-timesheet-additional-fields {
    @include hd-text;

    .fa-control-card {
        > section {
            padding-top: 0.5em; } }

    ul {
        list-style-type: circle;
        padding-inline-start: 1em;
        margin-bottom: 0;

        li {
            font-size: $body_2;
            margin: 0 0 0.5em 1em; } } }
