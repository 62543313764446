@import '../../../../styles/colours.sass';
@import '../../../../styles/shadows.sass';
@import '../../../../styles/partials/utils/typographyHelpers.sass';

.fa-planning-mode-toolbar--action-button {
    @include hd-text;
    user-select: none;
    font-size: 12px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $text-body;
    text-align: center;
    background-color: $grey-e;
    background-image: $scheduler-action-button-gradient;
    height: 42px;
    width: 68px;
    padding: 4px;
    border-radius: 2px;
    border: 1px solid $grey-c;
    cursor: pointer;

    &:hover {
        color: $grey-5;
        background-image: linear-gradient(to bottom, $ghost-white, $grey-c); }

    &:active, &:focus {
        @include scheduler-button-inset-shadow; }

    &.is-disabled {
        opacity: 0.6;
        border: 1px solid $grey-c;
        color: $grey-3;
        background: $grey-d;
        box-shadow: none;
        cursor: default; }

    > .fa-planning-mode-toolbar--action-button--icon-container {
        width: 24px;
        min-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        // The loading icon
        > .fa-ant-icon {
            font-size: 14px;
            transform: translateY(3px); }

        // The action icon
        > .fa-image {
            pointer-events: none;
            opacity: 0.75; } } }


