@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-timesheet-summary {
    @include system-font;
    @include compact-font-size;
    display: flex;
    justify-content: flex-end;
    font-weight: 400;
    color: $text-header;
    padding-top: 20px;

    &.has-top-border {
        border-top: 1px solid $grey-e; }

    .fa-timesheet-summary-container {
        width: 240px;

        &.summary-top-container {
            background: $grey-f;
            padding: 15px;
            border-radius: 6px; }

        &.summary-bottom-container {
            margin-top: 10px; }

        h2 {
            padding-top: 0; } } }

.fa-timesheet-summary-item {
    &.align-right {
        text-align: right; }

    &.is-bold {
        font-weight: bold; } }
