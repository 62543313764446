@import '../../styles/colours.sass';
@import '../../styles/shadows.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-key-code {
    @include system-font;
    color: $text-body;
    background: $ghost-white;
    border: 1px solid $grey-b;
    border-radius: 4px;
    padding: 2px 6px;
    @include material-shadow-0; }
