@import '../../styles/mixins/fa-date-picker.mixin.sass';

.fa-date-picker {
    @include fa-datepicker-styles; }

.fa-date-picker-dropdown {
    @include fa-datepicker-dropdown-styles;

    &.is-enterprise {
        @include fa-datepicker-dropdown-enterprise-styles; }

    &.is-rebrand {
        @include fa-datepicker-dropdown-rebrand-styles; } }

.is-enterprise {
    .fa-date-picker {
        @include fa-datepicker-enterprise-styles; } }

.is-rebrand {
    .fa-date-picker {
        @include fa-datepicker-rebrand-styles; } }
