@import '../../styles/colours.sass';

@mixin fa-upload-dragger($colour, $colour-light) {
    .fa-ant-icon {
        color: $colour; }

    .ant-upload {
        &.ant-upload-drag {
            &:not(.ant-upload-disabled) {
                &:hover {
                    border-color: $colour-light; } } } }

    .ant-upload-list {
        .ant-upload-list-item {
            height: 40px;
            line-height: 40px;
            border: 1px solid $grey-e;
            border-radius: 4px;

            .ant-upload-list-item-info {
                border-radius: 4px;

                .anticon-paper-clip {
                    color: $colour;
                    font-size: 20px;
                    top: 10px; }

                .ant-upload-list-item-name {
                    padding-left: 26px;
                    font-weight: bold; } }

            &:hover {
                border-color: $grey-d;

                .ant-upload-list-item-info {
                    background-color: $white; } }

            .anticon-close {
                font-size: 28px; } } } }


.fa-upload-dragger {
    &:not(.is-enterprise):not(.is-rebrand) {
        @include fa-upload-dragger($primary, $primary-light); }

    &.is-enterprise {
        @include fa-upload-dragger($enterprise-primary, $enterprise-primary-light); }

    &.is-rebrand {
        @include fa-upload-dragger($rebrand, $rebrand-light); } }
