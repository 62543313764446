@import '../colours.sass';

@mixin fa-selection {
    &::selection {
        background: $primary-text-highlight;
        color: $white; } }

@mixin fa-selection-enterprise {
    &::selection {
        background: $enterprise-primary-text-highlight;
        color: $white; } }

@mixin fa-selection-rebrand {
    &::selection {
        background: $rebrand-text-highlight;
        color: $white; } }
