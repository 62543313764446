@mixin fa-slider ($colour, $colour-oqaque-dark) {
    .fa-slider {
        .ant-slider-track, &.ant-slider:hover .ant-slider-track {
            background: $colour; }

        .ant-slider-handle {
            border: solid 2px $colour;
            box-sizing: border-box; }

        &.ant-tooltip-open {
            border: solid 2px $colour; }


        .ant-slider-dot-active, .ant-slider:hover .ant-slider-handle {
            border-color: $colour; }

        .ant-slider-handle:focus {
            border-color: $colour;
            box-shadow: 0 0 0 5px $colour-oqaque-dark;
            outline: none; }

        &.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
            border-color: $colour; }

        .ant-slider-dot {
            box-sizing: border-box; } } }
