@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';
@import '../../styles/partials/utils/componentHelpers.sass';

.fa-scheduler-job-popover-crew {
    @include plain-list;
    max-width: 300px;

    // for the loading skeleton
    ul {
        @include plain-list; }

    .fa-scheduler-job-popover-crew--list-item {
        &:not(:last-child) {
            margin-bottom: 1em; }

        > * {
            &:not(.fa-scheduler-job-popover-crew--crew-name) {
                margin-left: 2em; } }

        .fa-scheduler-job-popover-crew--crew-name {
            display: flex;
            align-items: center;

            .fa-job-state-icon {
                margin-top: 1px;
                margin-right: 0.5em; } }

        .fa-scheduler-job-popover-crew--crew-branch {
            margin-bottom: 0.5em;

            .fa-branch-colour-badge {
                margin-right: 2px; }

            i {
                font-style: italic;
                color: $text-subtitle; } }

        .fa-scheduler-job-popover-crew--crew-borrower-email {
            word-break: break-all; }

        .fa-scheduler-job-popover-crew--branch-data-missing {
            color: $emphasis-yellow;
            font-style: italic;

            .fa-ant-icon {
                position: relative;
                top: 2px; } } } }
