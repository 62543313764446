@import '../../styles/colours.sass';

.fa-modal {
    top: 5%;

    .ant-modal-close-x {
        display: flex;

        > i {
            margin: auto; } }

    .ant-modal-header {
        text-align: left;

        .ant-modal-title {
            font-weight: 300;
            font-size: 18px; } }

    .ant-modal-body {
        text-align: left;

        h1, h2, h3, h4, h5, h6 {
            margin-top: 0;
            margin-bottom: 0.5em;
            color: $text-header;
            font-weight: 500;
            line-height: 1em; }

        .ant-skeleton {
            ul, li {
                margin: 0; } } }

    .ant-modal-footer {
        > span:not(:first-child) {
            margin-left: 0.5em; } } }
