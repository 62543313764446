@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';
@import '../../styles/partials/utils/componentHelpers.sass';

.fa-scheduler-job-popover--overlay {
    @include hd-text;
    .ant-popover-inner-content {
        padding: 12px 0; }

    .fa-scheduler-job-popover--content {
        width: 300px;

        &.is-disabled {
            main {
                .fa-anchor {
                    pointer-events: none;
                    color: $text-body !important; } } }

        &:not(.is-restricted-access) {
            header {
                .fa-job-state-label {
                    margin-left: 1em;
                    margin-right: auto; } } }

        .fa-branch-access-warning {
            border-bottom-color: $white; }

        strong {
            font-weight: bold; }

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px 12px 12px;

            .fa-anchor {
                font-size: 18px;
                text-decoration: underline;

                &:hover {
                    opacity: 0.8; } } }

        main {
            font-size: $compact_body2;
            padding: 12px;
            max-height: 260px;
            overflow-y: auto;
            @include thin-scrollbar;
            @include popover-table;

            .fa-ant-button {
                &:hover {
                    > span {
                        text-decoration: underline; } } }

            table {
                td {
                    &:first-child {
                        width: 110px; }

                    &.has-additional-vertical-space {
                        &:not(:empty) {
                            padding-bottom: 0.5em; } }

                    ul {
                        @include plain-list; }

                    .fa-anchor {
                        word-break: break-all; } } } } }

    .fa-scheduler-job-popover--loading-skeleton {
        width: 300px;
        padding: 0.5em;

        ul {
            @include plain-list; } }

    .fa-ant-button {
        line-height: unset; } }
