@import '../../styles/colours.sass';
@import '../../styles/mixins/fa-button.mixin.sass';

.fa-popconfirm {
    .ant-btn-sm {
        @include fa-button-styles; } }

.fa-popconfirm.is-enterprise {
    .ant-btn-sm {
        @include fa-button-enterprise-styles; } }

.ant-popover-open {
    @include fa-button-styles; }

.is-enterprise {
    > .ant-popover-open {
        @include fa-button-enterprise-styles; } }
