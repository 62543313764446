@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-filter-panel {
    @include hd-text;
    width: 100%;

    header {
        display: flex;
        align-items: center;

        > * {
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
                margin-left: auto; } } }

    main {
        box-sizing: border-box;
        padding: 1em 0 0 0;
        border-top-width: 0;
        position: relative;
        max-height: 220px;
        overflow: auto;

        &.is-empty {
            height: 164px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $text-disabled;
            font-style: italic;
            border-radius: 2px; }

        .ant-tag {
            height: 38px;
            box-sizing: border-box;
            font-size: 14px;
            padding: 8px 28px 8px 8px;
            color: $text-body;
            margin-bottom: 0.5em;
            position: relative;

            .fa-filter-panel--tag-label {
                display: inline-block;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis; }

            .anticon-close {
                position: absolute;
                top: 10px;
                right: 6px;
                font-size: 16px; } } }

    .fa-filter-panel--clear-all-button {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: space-between;

        .ant-alert {
            font-size: 12px;
            padding: 4px 8px; } }

    &.is-read-only {
        main {
            height: unset;
            padding: 0;

            &.is-empty {
                display: none; }

            .ant-tag {
                padding: 8px;

                &:hover {
                    opacity: 1; } } } } }
