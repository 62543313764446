@import '../../styles/colours.sass';

.fa-switch {
    cursor: pointer;
    display: flex;
    align-items: center;

    .ant-switch {
        transition: all 0.1s ease-in-out;

        &:focus {
            box-shadow: none; }

        &:not(.ant-switch-small) {
            min-width: 38px; } }

    .ant-switch-checked {
        background-color: $primary; }

    .fa-switch-label {
        margin-left: 6px;
        user-select: none; }

    &.is-enterprise {
        .ant-switch-checked {
            background-color: $enterprise-primary; } }

    &.is-rebrand {
        .ant-switch-checked {
            background-color: $rebrand; } }

    &.is-disabled {
        cursor: not-allowed; } }
