@import '../../styles/mixins/fa-button.mixin.sass';

.fa-ant-button {
    @include fa-button-styles; }

.is-enterprise {
    > .fa-ant-button {
        @include fa-button-enterprise-styles; } }

.is-rebrand {
    > .fa-ant-button {
        @include fa-button-rebrand-styles; } }
