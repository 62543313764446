@import '../../styles/colours.sass';

@mixin fa-nav-menu($colour, $colour-light, $colour-dark, $colour-text-highlight) {
    * {
        &:focus {
            outline: 2px dashed $colour-text-highlight; } }
    li {
        margin-left: 0;

        &.ant-menu-item {
            padding: 0;

            &:hover, &.ant-menu-item-active {
                color: $colour-light;
                border-bottom-color: $colour-light; }

            &.ant-menu-item-selected {
                color: $colour;
                border-bottom-color: $colour;

                > a {
                    color: $colour; } }
            > a {
                &:hover {
                    color: $colour-light; }

                &:active {
                    color: $colour; } } }

        &.ant-menu-submenu {
            &:hover, &.ant-menu-submenu-active, &.ant-menu-submenu-open {
                color: $colour-light;
                border-bottom-color: $colour-light; }

            &.ant-menu-submenu-selected {
                color: $colour;
                border-bottom-color: $colour; }

            .ant-menu-submenu-title {
                &:hover {
                    color: $colour-light; } } } }

    .ant-menu-submenu-title {
        .fa-ant-icon {
            margin-right: 0; } }

    &.ant-menu-dark {
        background: darken($colour-dark, 15%);

        li {
            &.ant-menu-item {
                &.ant-menu-item-selected {
                    color: $white;
                    background-color: $colour-dark; } } } } }

@mixin ant-menu-submenu-popup($colour, $colour-light, $colour-text-highlight, $colour-opaque-light-1) {
    * {
        &:focus {
            outline: 2px dashed $colour-text-highlight; } }

    .ant-menu-item {
        margin: 0 !important;
        padding: 8px;
        box-sizing: content-box;

        &.ant-menu-item-active {
            color: $colour-light; }

        &.ant-menu-item-selected {
            color: $colour;
            background-color: $colour-opaque-light-1;

            > a {
                color: $colour; } }

        > a {
            &:hover {
                color: $colour-light; }

            &:active {
                color: $colour; } }

        > .fa-nav-menu--button {
            text-align: left;
            margin: 4px 0;
            width: 100%; } } }

.fa-nav-menu {
    &:not(.is-rebrand) {
        @include fa-nav-menu($primary, $primary-light, $primary-dark, $primary-text-highlight); }

    &.is-rebrand {
        @include fa-nav-menu($rebrand, $rebrand-light, $rebrand-dark, $rebrand-text-highlight); } }

.ant-menu-submenu-popup {
    &:not(.is-rebrand) {
        @include ant-menu-submenu-popup($primary, $primary-light, $primary-text-highlight, $primary-opaque-light-1); }

    &.is-rebrand {
        @include ant-menu-submenu-popup($rebrand, $rebrand-light, $rebrand-text-highlight, $rebrand-opaque-light-1); } }

.fa-nav-menu--button {
    color: inherit;
    line-height: inherit;
    padding: 0 20px;
    border: none;
    border-radius: 4px;
    background: transparent;
    cursor: pointer;

    > a {
        color: inherit;
        transition: none; } }

.fa-nav-menu--link {
    color: inherit;
    padding: 0 20px; }
