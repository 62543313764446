@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-overline-label {
    @include system-font;
    @include hd-text;
    font-weight: 400;
    font-size: $compact_overline;
    color: $text-subtitle;
    display: block;
    margin-bottom: 0;

    &.is-bold {
        color: $text-header;
        font-weight: normal; } }
