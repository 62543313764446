@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-collapse {
    > .ant-collapse-item {
        > .ant-collapse-header {
            @include system-font;
            @include hd-text;
            font-weight: lighter;
            font-size: $compact_h6;
            padding-left: 20px;

            > .ant-collapse-arrow {
                left: 0; } } } }
