@import '../../colours.sass';

@mixin hd-text {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

@mixin system-font {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

@mixin base-font-config {
    font-variant: tabular-nums;
    color: $text-body; }

@mixin base-font-size {
    @include base-font-config;
    font-size: 14px;
    line-height: 21px; }

@mixin compact-font-size {
    @include base-font-config;
    font-size: 12px;
    line-height: 1.5; }

@mixin fa-select-empty-placeholder {
    padding: 15px 20px;
    color: $grey-b;
    font-style: italic; }

// Restrict text to a single line, hiding overflow and adding an ellipsis (...)
// Note: element needs a width (px) and must not have display: inline for this to work
@mixin one-line-of-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
