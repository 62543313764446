@import '../../styles/mixins/fa-select.mixin.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-select-input {
    @include fa-select-styles; }

.is-enterprise {
    > .fa-select-input {
        @include fa-select-enterprise-styles; } }

.is-rebrand {
    > .fa-select-input {
        @include fa-select-rebrand-styles; } }

.fa-select-dropdown {
    @include fa-select-dropdown-styles;

    &.is-enterprise {
        @include fa-select-dropdown-enterprise-styles; }

    &.is-rebrand {
        @include fa-select-dropdown-rebrand-styles; } }


/** This style overrides some global styles that are set in the web app, which are causing issues when FASelect is imported in */
.ant-select-dropdown-menu {
    li {
        margin-left: 0px;
        text-align: left; } }

.ant-select-dropdown--empty {
    .ant-select-dropdown-menu {
        > .ant-select-dropdown-menu-item {
            @include fa-select-empty-placeholder; } } }
