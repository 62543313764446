@import '../../styles/colours.sass';

.fa-notification-card {
    text-align: left;

    strong {
        font-weight: normal !important;
        color: $text-header; }

    .fa-branch-colour-badge {
        margin-right: 2px; }

    .ant-card-body {
        padding: 16px 24px 8px 34px;

        .fa-notification-card--read-status {
            position: absolute;
            display: flex;
            justify-content: space-around;
            width: 14px;
            height: 14px;
            left: 10px;
            top: 20px;
            background: transparent;
            border-radius: 50%;
            cursor: pointer;

            > div {
                align-self: center;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: $primary; }

            &:hover {
                background: rgba($primary, 0.3); }

            &.is-enterprise {
                > div {
                    background: $enterprise-primary; }

                &:hover {
                    background: rgba($enterprise-primary, 0.3); } }

            &.is-rebrand {
                > div {
                    background: $rebrand; }

                &:hover {
                    background: rgba($rebrand, 0.3); } }

            &.is-read {
                > div {
                    background: $grey-8; }

                &:hover {
                    background: $grey-c; } } }

        header {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $text-header;
            margin-left: 2px;

            strong {
                font-weight: 500 !important; }

            .fa-job-state-icon {
                margin: 0 4px 0 -4px; }

            .fa-notification-card--entity-link {
                font-size: 12px;
                margin-left: auto; } }

        main {
            margin-top: 6px;
            font-size: 13px;

            > div {
                &:first-child {
                    margin-bottom: 16px; } }

            .fa-notification-card--pause-reason {
                &:not(:last-child) {
                    margin-bottom: 8px; }

                > h1 {
                    font-size: 12px;
                    font-weight: 400;
                    color: $text-subtitle;
                    margin: 0;
                    // overcome global webapp styles
                    padding: 0;
                    float: unset;
                    display: block;
                    line-height: inherit; } } }

        footer {
            font-size: 12px;
            font-weight: 400;
            color: $text-subtitle;
            text-align: right;
            margin-top: 18px; }

        .fa-anchor {
            text-decoration: none !important; } }

    &:not(:last-child) {
        border-bottom: 1px solid $grey-e; }

    &.is-read {
        background: $ghost-white;
        color: $text-subtitle;

        strong {
            color: $text-subtitle; }

        header {
            color: $text-subtitle; }

        main {
            opacity: 0.7;

            .fa-anchor {
                opacity: 0.8;

                &:hover {
                    opacity: 1; } } } }

    &.is-error {
        .ant-card-body {
            > span:first-child {
                color: $danger;
                margin-right: 4px; } } }

    &.is-compact {
        .ant-card-body {
            padding: 0; } } }
