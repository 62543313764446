@import '../../styles/sizes.sass';
@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';
@import '../../styles/partials/utils/componentHelpers.sass';

.fa-scheduler-learning-centre {
    @include system-font;

    p {
        margin-bottom: 1em; }

    .ant-modal-title {
        > div {
            display: flex;
            align-items: center;

            .fa-ant-icon {
                font-size: 24px;
                color: $enterprise-primary; } } }

    .ant-modal-body {
        max-height: calc(100vh - 200px);
        font-size: 14px;
        overflow-y: auto;
        @include thin-scrollbar;

        section {
            display: flex;
            padding: 0.5em;

            &:last-child {
                margin-bottom: 1em; }

            > div {
                width: 50%; }

            mark {
                background: inherit;
                color: $text-disabled; } }

        .fa-section-title {
            font-size: 16px;
            margin-bottom: 1em; } } }
