@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';
@import '../../styles/partials/utils/tableHelpers.sass';

.fa-data-table {
    table {
        margin-bottom: 0; }

    td {
        padding: 8px; }

    tr.fa-data-table--row {
        height: 52px;
        color: $text-body;

        &.is-disabled {
            td.fa-ant-table-column-time,
            td.fa-ant-table-column-day,
            td.fa-ant-table-column-allowed_overtime {
                background-color: $grey-f; } }

        &:hover {
            > td {
                background: initial; } }

        .stacked-label {
            white-space: pre-wrap;
            color: $text-disabled;
            line-height: 120%;
            font-size: 12px; }

        .end-time {
            display: flex;
            justify-content: left;
            align-items: center; }

        .is-on-left {
            text-align: end;
            margin-right: 6px; }

        .is-on-right {
            min-width: 66px;
            margin-left: 6px; } }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        padding: 14px; }

    .ant-table-placeholder {
        font-style: italic;

        // If the table has a footer, don't render bottom radius on the placeholder
        &:not(:last-child) {
            border-radius: 0; } }

    &.is-compact {
        .ant-table {
            font-size: 12px;

            .fa-data-table--row {
                height: 42px; }

            .ant-table-placeholder {
                font-size: 12px;
                padding: 10px 16px; }

            .ant-table-thead > tr > th {
                padding: 12px; }

            .ant-table-tbody > tr > td {
                padding: 4px 12px; } } }

    &.has-hd-text {
        @include hd-text; }

    .ant-pagination-item-active,
    .ant-pagination-item,
    .ant-pagination-next:not(.ant-pagination-disabled),
    .ant-pagination-prev:not(.ant-pagination-disabled) {
        border-color: $primary;
        a, &:active {
            color: $primary; }

        &:hover, &:focus {
            border-color: $primary-dark;
            a {
                color: $primary-dark; } } }

    @include has-internal-borders-only; }

.is-enterprise {
    .fa-data-table {
        .ant-pagination-item-active,
        .ant-pagination-item,
        .ant-pagination-next:not(.ant-pagination-disabled),
        .ant-pagination-prev:not(.ant-pagination-disabled) {
            border-color: $enterprise-primary;
            a, &:active {
                color: $enterprise-primary; }

            &:hover {
                border-color: $enterprise-primary-dark;
                a {
                    color: $enterprise-primary-dark; } } } } }
