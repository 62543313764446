@import '../../styles/partials/utils/componentHelpers.sass';
@import '../../styles/colours.sass';

.fa-scroll-container {
    position: relative;

    .fa-scroll-container--inner {
        height: 100%;
        width: 100%;

        @include thin-scrollbar; }

    // This is the element with the shadow
    // This trick is necessary to allow the shadow to cover the child elements
    // @see https://stackoverflow.com/questions/13188828/inset-box-shadow-beneath-content
    //
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 0 0 0 $scroll-container-box-shadow;
        pointer-events: none;
        z-index: 1;
        transition: box-shadow 0.2s ease-in-out; }

    &.is-vertical {
        height: 100%;

        .fa-scroll-container--inner {
            overflow-x: hidden;
            overflow-y: auto; }

        &.has-top-shadow {
            &:before {
                box-shadow: inset 0 3px 3px 0 $scroll-container-box-shadow; } }

        &.has-bottom-shadow {
            &:before {
                box-shadow: inset 0 -3px 3px 0 $scroll-container-box-shadow; } }

        &.has-top-shadow.has-bottom-shadow {
            &:before {
                box-shadow: inset 0 3px 3px 0 $scroll-container-box-shadow, inset 0 -3px 3px 0 $scroll-container-box-shadow; } } }

    &.is-horizontal {
        .fa-scroll-container--inner {
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden; }

        &.has-left-shadow {
            &:before {
                box-shadow: inset 3px 0 3px 0 $scroll-container-box-shadow; } }

        &.has-right-shadow {
            &:before {
                box-shadow: inset -3px 0 3px 0 $scroll-container-box-shadow; } }

        &.has-left-shadow.has-right-shadow {
            &:before {
                box-shadow: inset -3px 0 3px 0 $scroll-container-box-shadow, inset 3px 0 3px 0 $scroll-container-box-shadow; } } } }
