@import '../../styles/colours.sass';
@import '../../styles/mixins/fa-input.mixin.sass';
@import '../../styles/mixins/fa-selection.mixin.sass';

.fa-textArea {
    @include fa-input($primary, $primary-opaque-light-2);
    @include fa-selection; }

.is-enterprise {
    .fa-textArea {
        @include fa-input($enterprise-primary, $enterprise-primary-opaque-light-2);
        @include fa-selection-enterprise; } }

.is-rebrand {
    .fa-textArea {
        @include fa-input($rebrand-opaque-light-2, $rebrand-opaque-light-2);
        @include fa-selection-rebrand; } }

.has-error {
    .fa-textArea {
        @include fa-input($danger, $danger-opaque); } }
