@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-job-state-label {
    @include hd-text;
    display: flex;
    align-items: center;
    font-size: $compact_body2;
    font-weight: bold;
    height: 24px;
    width: fit-content;
    padding: 2px 6px;
    border-radius: 3px;
    color: $white;

    &.is-size-small {
        font-size: 10px;
        font-weight: normal;
        height: 20px;
        padding: 1px 4px; }

    &.is-size-large {
        font-size: $body_2;
        height: 28px;
        padding: 2px 8px; } }
