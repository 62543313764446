@import '../colours.sass';
// For backwards-compatibility,
// style the ::selection colours of
// all inputs, except those with classnames
// beginning with 'dijit'

input:not([class^="dijit"])::selection {
    background: $primary-text-highlight;
    color: $white; }

.is-enterprise {
    input:not([class^="dijit"])::selection {
        background: $enterprise-primary-text-highlight;
        color: $white; } }

.is-rebrand {
    input:not([class^="dijit"])::selection {
        background: $rebrand-text-highlight;
        color: $white; } }
