@import '../../styles/colours.sass';

.fa-empty-settings-list-card {
    background: $grey-f;
    margin-bottom: 8px;

    .ant-card-body {
        padding: 15px 20px;

        .fa-empty-settings-list-card--message {
            color: $grey-b;
            font-style: italic;

            .fa-ant-button {
                > span {
                    text-decoration: underline; } } } } }
