@import '../../styles/colours.sass';

@mixin fa-tabs ($colour, $colour-light, $colour-dark) {
    .ant-tabs-ink-bar {
        background-color: $colour; }

    .ant-tabs-tab {
        &:not(.ant-tabs-tab-disabled) {
            &.ant-tabs-tab-active {
                color: $colour; }

            &:hover {
                color: $colour-light; }

            &:active {
                color: $colour-dark; } } } }

.fa-tabs.ant-tabs {
    box-sizing: border-box;

    .ant-tabs-bar {
        user-select: none; }

    @include fa-tabs($primary, $primary-light, $primary-dark); }

.is-enterprise {
    .fa-tabs.ant-tabs {
        @include fa-tabs($enterprise-primary, $enterprise-primary-light, $enterprise-primary-dark); } }

.is-rebrand {
    .fa-tabs.ant-tabs {
        @include fa-tabs($rebrand, $rebrand-light, $rebrand-dark); } }

