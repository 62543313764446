// sass-lint:disable no-color-literals
@mixin material-shadow-transition {
    transition: box-shadow 0.3s cubic-bezier(.25, .8, .25, 1); }

@mixin material-shadow-0 {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1); }

@mixin material-shadow-1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

@mixin material-shadow-2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

@mixin material-shadow-3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }

@mixin material-shadow-4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); }

@mixin material-shadow-5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22); }

@mixin scheduler-button-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); }

@mixin scheduler-button-inset-shadow {
    box-shadow: inset rgba(0, 0, 0, 0.2) 0 0 4px; }
