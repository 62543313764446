@import '../../styles/colours.sass';

.fa-colour-picker {
    cursor: pointer;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border: 1px solid $white;
    border-radius: 4px;

    &.ant-popover-open:hover {
        border-color: $grey-5 !important; } }


.fa-colour-picker--popover {
    .ant-popover-inner-content {
        padding: 0;

        > div {
            display: grid;
            grid-template-columns: 30px 30px;

            .fa-colour-picker--swatch {
                cursor: pointer;
                display: flex;
                width: 30px;
                height: 30px;
                justify-content: center;
                align-items: center;
                color: $white;
                box-sizing: border-box;
                border: 1px solid;

                &:hover {
                    border: 2px solid $white; } } } } }
