@import '../../styles/colours.sass';
@import '../../styles/mixins/fa-radio-button.mixin.sass';

.fa-radio-button-group {
    .fa-radio-button-group--radio-group {
        @include fa-radio-button($primary);

        .ant-radio {
            margin-top: 3px;
            max-height: 16px; }

        .ant-radio-inner {
            box-sizing: border-box; }

        .fa-radio-button-group--radio-button {
            display: flex;
            flex-direction: row;
            height: auto;
            line-height: 20px;
            white-space: pre-wrap;
            margin-bottom: 10px; } }

    .fa-radio-button-group--info {
        display: flex;
        flex-direction: row;
        margin-left: 1px;
        margin-bottom: 20px;

        .fa-radio-button-group--info-icon {
            font-size: 16px; }

        .fa-radio-button-group--info-message {
            margin-left: 8px;
            margin-top: -3px;
            margin-bottom: 3px;
            white-space: pre-wrap; } }

    .fa-selectable-message {
        &.is-compact {
            > p {
                margin-top: 2px;
                margin-bottom: 0; } } } }


.is-enterprise {
    > .fa-radio-button-group {
        .fa-radio-button-group--radio-group {
            @include fa-radio-button($enterprise-primary); } } }
