@import '../colours.sass';
@import '../shadows.sass';
@import '../partials/utils/typographyHelpers.sass';

@mixin scheduler-button {
    @include hd-text;
    @include scheduler-button-shadow;
    user-select: none;
    position: relative;
    display: inline-block;
    font-size: 12px;
    height: 30px;
    padding: 2px 12px;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
    margin-left: 4px;
    box-sizing: border-box;
    text-shadow: none;
    color: $white;
    text-decoration: none;
    line-height: 26px;
    background-color: $grey-4; }
