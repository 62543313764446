@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';
@import '../../styles/partials/utils/componentHelpers.sass';

.fa-searchable-checklist {
    @include hd-text;

    .ant-list-split {
        margin-top: 6px;

        .ant-list-item {
            margin: 0 4px;
            padding: 6px 0;
            position: relative;

            .ant-checkbox-wrapper {
                font-size: 14px;
                max-width: 280px;
                padding-top: 1px;
                @include one-line-of-text; }

            .fa-searchable-checklist--count-label {
                font-size: 12px;
                color: $text-subtitle;
                margin-top: 0;
                margin-bottom: 4px;
                margin-left: auto;

                &:hover {
                    color: $text-subtitle; } }

            .fa-searchable-checklist--none-item {
                font-size: 12px;
                color: $text-subtitle; }

            &:not(:first-child) {
                border-bottom: none;
                padding: 6px 0 0 0; }

            .fa-job-state-icon {
                display: inline-block;
                position: absolute;
                right: 4px;
                top: 4px; } }

        .fa-searchable-checklist--scroll-container {
            &.is-searching {
                display: none; }

            overflow-y: auto;
            @include thin-scrollbar;

            .ant-list-item {
                border-bottom: none;
                padding: 6px 0 0 0; } }

        .ant-list-item-meta-description {
            font-size: 11px;

            .fa-searchable-checklist--item-detail {
                display: flex;
                flex-direction: column;
                margin-left: 24px;

                .fa-searchable-checklist--attribute-name {
                    margin-right: 4px; } } } } }

.fa-searchable-checklist--count-warning {
    @include warning-popover; }
