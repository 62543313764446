
@import '../../styles/colours.sass';

.fa-datetime-range--card {
    &.is-disabled {
        background: $grey-f;

        .fa-datetime-range--start-label,
        .fa-datetime-range--end-label {
            span {
                opacity: 0.7; } } }

    &.is-compact {
        span, div, input {
            font-size: 12px; }

        .fa-hours-time-picker-popup {
            li {
                font-size: 12px; } } }

    .fa-datetime-range--validation {
        width: 250px;
        margin: 0;
        display: inline-block; }

    .fa-datetime-range--start {
        padding-top: 4px;
        display: inline-block; }

    .fa-datetime-range--end {
        display: inline-block;
        margin-top: 10px; }

    .fa-datetime-range--start-label {
        line-height: 10px; }

    .fa-date-picker {
        width: 130px; } }
