@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';
@import '../../styles/partials/utils/componentHelpers.sass';

.fa-timesheet-table {

    .fa-section-title {
        > span {
            font-size: 15px;
            font-weight: 300; } }

    .fa-timesheet-table--date {
        margin-left: 8px;
        margin-top: 4px; }

    .fa-data-table {
        margin: 0.5em 0 1em 0;

        .ant-table-body {
            border: 1px solid $grey-e; }

        .ant-table-footer {
            border: none;
            background: $white;
            padding: 0;
            font-size: $body_2; }

        &.is-compact {
            .ant-table-thead {
                > tr {
                    > th {
                        padding: 6px 12px; } } } }

        .fa-data-table--row {
            small {
                font-size: $compact_caption;
                color: $text-subtitle; } } } }
