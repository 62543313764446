.fa-branch-borrow-icon {
    height: 25px;

    img {
        height: 25px;
        width: auto; } }

.fa-branch-borrow-icon--overlay {
    font-size: 12px;

    p:last-child {
        margin-bottom: 0; } }
