@import '../../styles/colours.sass';
@import '../../styles/mixins/fa-select.mixin.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-select-input {
    @include fa-select-styles; }

.is-enterprise {
    > .fa-select-input {
        @include fa-select-enterprise-styles; } }

.is-rebrand {
    > .fa-select-input {
        @include fa-select-rebrand-styles; } }

.fa-group-select {
    &.fa-select-dropdown {
        @include fa-select-dropdown-styles;
        min-width: 300px;

        &.is-enterprise {
            @include fa-select-dropdown-enterprise-styles; }

        &.is-rebrand {
            @include fa-select-dropdown-rebrand-styles; }

        .ant-select-dropdown-menu {
            .ant-select-dropdown-menu-item-group-title {
                background-color: $grey-f;
                border-bottom: 1px solid $grey-d;
                // text colour should match the text colour of the options
                color: $text-body;
                font-size: 1em;
                margin-bottom: 8px; }

            .ant-select-dropdown-menu-item {
                // text colour should match group title text colour
                color: $text-body; }

            .ant-select-dropdown-menu-item-group:not(:first-child) {
                .ant-select-dropdown-menu-item-group-title {
                    border-top: 1px solid $grey-d;
                    margin-top: 8px; } } } } }
