@import '../../styles/colours.sass';
@import '../../styles/sizes.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

h2.fa-section-title {
    @include system-font;
    display: flex;
    align-items: center;
    font-size: $h6;
    font-weight: 400;
    color: $text-header;
    margin-bottom: 20px;
    padding: 0;

    small {
        margin-left: 0.5em;
        font-size: $compact_subtitle_1;
        color: $text-subtitle;
        margin-top: 5px; }

    &.has-hd-text {
        @include hd-text; }

    &.has-no-margin {
        margin-bottom: 0; } }
