@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-job-feed {
    h1 {
        @include compact-font-size;
        font-size: 12px !important;
        font-weight: bold;
        color: $black; }

    .fa-data-table {
        table {
            border: 0;

            tr, th, td {
                padding: 2px 4px 2px 0 !important;
                border: 0;
                background-color: white; }

            td {
                font-size: 12px; } }

        .fa-data-table--row {
            height: inherit !important; }

        .ant-table-placeholder {
            display: none; } }

    .fa-spinner {
        display: inline-block;
        margin-left: 180px; } }
