@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-tag {
    @include hd-text;
    user-select: none;
    pointer-events: none;
    vertical-align: text-bottom;

    &.fa-tag-size-large {
        border-radius: 8px;
        font-size: 12px;
        height: 26px;
        line-height: 26px; } }
