@import '../../styles/colours.sass';
@import '../../styles/partials/inputs';
@import '../../styles/mixins/fa-input-number.mixin.sass';

.fa-input-number {
    @include fa-input-number; }

.is-enterprise {
    > .fa-input-number {
        @include fa-input-number-enterprise; } }

.is-rebrand {
    > .fa-input-number {
        @include fa-input-number-rebrand; } }
