@import '../../styles/sizes.sass';
@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-selectable-message {
    @include system-font;
    @include base-font-size;
    @include hd-text;
    color: $text-body;
    margin-bottom: 20px;

    h3 {
        color: $text-header;
        font-size: $subtitle_1;
        line-height: $subtitle_1;
        font-weight: lighter;
        margin-bottom: 0.2em; }

    &.is-selected {
        h3 {
            font-weight: 600; } }

    &.is-disabled {
        h3, p {
            color: $text-disabled; } }

    &.is-compact {
        h3 {
            font-size: $compact_subtitle_1;
            line-height: $compact_subtitle_1; }
        p {
            font-size: $compact_body2; } } }
