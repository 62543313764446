@import '../../styles/colours.sass';

@mixin fa-input-number-base ($baseColour, $opaqueColour) {
    input {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        box-sizing: border-box; }

    &:not(.ant-input-number-disabled) {
        &:hover {
            border-color: $baseColour; }

        .ant-input-number-handler:not(.ant-input-number-handler-down-disabled):hover {
            .ant-input-number-handler-up-inner,
            .ant-input-number-handler-down-inner {
                color: $baseColour; } }

        &.ant-input-number-focused {
            border-color: $baseColour;
            box-shadow: 0 0 0 2px $opaqueColour; } }

    &.ant-input-number-sm {
        font-size: 12px; } }

@mixin fa-input-number {
    @include fa-input-number-base($primary, $primary-opaque-light-2); }

@mixin fa-input-number-enterprise {
    @include fa-input-number-base($enterprise-primary, $enterprise-primary-opaque-light-2); }

@mixin fa-input-number-rebrand {
    @include fa-input-number-base($rebrand, $rebrand-opaque-light-2); }
