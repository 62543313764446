@import '../../styles/colours.sass';

@mixin footer-button-for-colour($colour) {
    // Need this instead of small button from ant to match dojo styles
    font-size: 12px;
    height: 24px;
    line-height: 24px;

    // This style is not being set correctly from the base button style
    &.ant-btn-clicked:after {
        border-color: $colour; } }


@mixin fa-footer-button-styles {
    @include footer-button-for-colour($primary); }

@mixin fa-footer-button-enterprise-styles {
    @include footer-button-for-colour($enterprise-primary); }
