.ant-checkbox-group {
    .fa-checked-multi-select.ant-list {
        height: 300px;
        padding: 1em;
        overflow: scroll;

        li.ant-list-item {
            padding-left: 0;
            margin-left: 10px;
            border-bottom: none;
            padding: 5px 0; } }

    .fa-react-checkbox {
        span:last-child:not(.ant-checkbox-inner) {
            display: block;
            padding-left: 24px;
            margin-top: -22px; } } }
