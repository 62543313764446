@import '../../styles/colours.sass';
@import '../../styles/mixins/fa-slider.mixin.sass';
@import '../../styles/mixins/fa-input-number.mixin.sass';

.fa-slider--container {
    display: flex;

    .fa-slider-bar--container {
        margin-right: 15px;
        width: 65%;

        @include fa-slider($primary, $primary-opaque-dark); }

    .fa-slider-input--container {
        margin-top: 5px;

        .ant-input-number {
            @include fa-input-number;

            &.ant-input-number-sm {
                width: 60px; } } } }

.is-enterprise {
    .fa-slider--container {
        .fa-slider-bar--container {
            @include fa-slider($enterprise-primary, $enterprise-primary-opaque-dark); }

        .fa-slider-input--container {
            margin-top: 5px;

            .ant-input-number {
                @include fa-input-number-enterprise; } } } }

