@import '../../styles/colours.sass';
@import '../../styles/mixins/fa-input.mixin.sass';
@import '../../styles/mixins/fa-selection.mixin.sass';

.fa-input {
    @include fa-input($primary, $primary-opaque-light-2);
    @include fa-selection; }

.ant-input-affix-wrapper:hover {
    .ant-input:not(.ant-input-disabled) {
        border-color: $primary; } }


.is-enterprise {
    .fa-input {
        @include fa-input($enterprise-primary, $enterprise-primary-opaque-light-2);
        @include fa-selection-enterprise; }

    .ant-input-affix-wrapper:hover {
        .ant-input:not(.ant-input-disabled) {
            border-color: $enterprise-primary; } } }

.is-rebrand {
    .fa-input {
        @include fa-input($rebrand, $rebrand-opaque-light-2);
        @include fa-selection-rebrand; }

    .ant-input-affix-wrapper:hover {
        .ant-input:not(.ant-input-disabled) {
            border-color: $rebrand; } } }
