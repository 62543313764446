@import '../../styles/colours.sass';
@import '../../styles/partials/utils/typographyHelpers.sass';

.fa-banner {
    @include hd-text;
    width: 100vw;

    .ant-alert-message {
        color: $white; }

    &.ant-alert-success {
        background: $primary; }

    &.ant-alert-error {
        background: $danger; }

    .anticon-close {
        color: $white;
        font-size: 18px;
        margin-top: 1px;

        &:hover {
            color: $grey-f; }

        &:active {
            color: $grey-d; } } }

.is-enterprise {
    > .fa-banner {
        &.ant-alert-success {
            background: $enterprise-primary; } } }
